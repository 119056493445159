import React from 'react'
import PropTypes from 'prop-types'
import ArticleThumbnail  from './ArticleThumbnail'
import '../styles/ArticleRoll.sass'

class ArticleRoll extends React.Component {
  render() {
    const { edges: posts = [] } = this.props.allMarkdownRemark
    const columns = this.props.columns || 2
    const columnSize = Math.floor(12 / columns)
    const postRows = posts.reduce((rows, {node: post}, index) => {
      const meta = {
        size: `is-${columnSize}`,
        post
      }

      if (index % columns === 0) {
        const newRow = [meta]
        rows.push(newRow)
      } else {
        rows[rows.length - 1].push(meta)
      }

      return rows
    }, [])

    return (
      <div className="articleroll">
        {posts &&
          postRows.map((row, index) => (
            <div className="columns" key={index}>
            {row.map(({post, size}) => (
              <div className={`column ${size} articleroll__article`} key={post.id}>
                <ArticleThumbnail
                  slug={post.fields.slug}
                  featuredimage={post.frontmatter.featuredimage}
                  columnist={post.frontmatter.columnist}
                  title={post.frontmatter.title}
                  excerpt={post.excerpt}
                  date={post.frontmatter.date}
                />
              </div>
            ))}
            </div>
          ))}
      </div>
    )
  }
}

ArticleRoll.propTypes = {
  allMarkdownRemark: PropTypes.shape({
    edges: PropTypes.array,
    columns: PropTypes.number
  })
}

export default ArticleRoll
