import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import ArticleRoll from '../components/ArticleRoll'
import SectionTitle from '../components/SectionTitle'
import '../styles/ArticleIndex.sass'

const ArticleIndexPage = (props) => {
  const { previousPagePath, nextPagePath } = props.pageContext
  return (
    <Layout>
      <section className="section articleindex">
        <div className="container content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: '6rem' }}
            >
              <h1>
                <SectionTitle>所有文章</SectionTitle>
              </h1>
              <ArticleRoll allMarkdownRemark={props.data.allMarkdownRemark} columns="2" />
              <div className="articleindex__nav">
                {previousPagePath ? (<Link className="primary-link" to={previousPagePath}>《 上一页</Link>) : null}
                {nextPagePath ? (<Link className="primary-link" to={nextPagePath}>下一页 》</Link>) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ArticleIndexPage

export const articleIndexPageQuery = graphql`
  query articleIndexPageQuery ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "article" }
        }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 60, truncate: true)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "YYYY/MM/DD")
            columnist {
              ...simpleColumnistFields
            }
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`