import React from 'react'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import '../styles/ArticleThumbnail.sass'

const ArticleThumbnail = ({
  slug,
  featuredimage,
  title,
  excerpt,
  columnist,
  date
}) => {
  const tail = [date]
  if (columnist) {
    tail.unshift(columnist.meta.title)
  }
  return (
    <Link
      to={slug}
      style={{display: 'block', height: '100%'}}
    >
      <article className="content pointer articlethumbnail">
        <div>
          {featuredimage ? (
            <BackgroundImage
              className="roll-cover"
              fluid={featuredimage.childImageSharp.fluid}
            />
          ) : (
            <div className="roll-cover bg-default"></div>
          )}
          <h2 className="title">{title}</h2>
        </div>
        <div>
          <p className="excerp">{excerpt}</p>
          <div className="meta">{tail.join(' - ')}</div>
        </div>
      </article>
    </Link>
  )
}

export default ArticleThumbnail
